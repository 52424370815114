import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../views/HomePage.vue';
import Login from '../views/LoginPage.vue';
import Murojaah from '../views/MurojaahPage.vue';
import Hafalan from '../views/HafalanPage.vue';

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/home',
    name: 'Home',
    component: HomePage,
    children: [
      {
        path: 'murojaah',
        component: Murojaah
      },
      {
        path: 'hafalan',
        component: Hafalan
      }
    ]
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
