<template>
  <div class="container" id="container">
    <div class="form-container">
          <div class="overlay-panel">
            <img class="logo" src="img/logo-only.png" alt="logo">
            <h1 class="title">Tahfidz Al-qur'an Plus</h1>
            <p>Pondok Pesantren Jam'iyyah Islamiyyah</p>
            <img class="login-image" src="img/login-image.png" alt="logo">
            <p>To keep connected with us please login with your personal info</p>
            
            <form @submit.prevent="login">
              <h1>Sign in</h1>
              <input type="text" v-model="username" placeholder="Username" required />
              <input type="password" v-model="password" placeholder="Password" required />
              <button type="submit">Login</button>
            </form>
          </div>
        
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'LoginPage',
  data() {
    return {
      username: '',
      password: ''
    };
  },
  methods: {
    async login() {
      try {
        const response = await axios.post('https://sekolah.tahfidzalquranplus.com/api/login', {
          email: this.username,
          password: this.password
        });

        // Cek respons dari server
        if (response.status) {
          // Simpan token atau data session jika diperlukan
          localStorage.setItem('token', response.data.jwt);
          alert('Login berhasil');
          // Arahkan ke halaman home

          this.$router.push('/home');
        } else {
          alert('Login gagal: ' + response.data.message);
        }
      } catch (error) {
        // Tampilkan error jika ada masalah
        alert('Terjadi kesalahan: ' + error.message);
      }
    }
  }
};
</script>

<style scoped>
* {
        box-sizing: border-box;
      }

      body {
        background: #f6f5f7;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-family: 'Montserrat', sans-serif;
        height: 100vh;
        width: 100%;
        margin: 0;
        padding: 0;
      }
     

      .container{
        margin: 0;
        padding: 0;
      }

      h1 {
        font-weight: bold;
        margin: 0;
      }

      h2 {
        text-align: center;
      }

      p {
        font-size: 14px;
        font-weight: 100;
        line-height: 20px;
        letter-spacing: 0.5px;
        margin: 20px 0 30px;
      }

      span {
        font-size: 12px;
      }

      a {
        color: #333;
        font-size: 14px;
        text-decoration: none;
        margin: 15px 0;
      }

      button {
        border-radius: 20px;
        border: 1px solid #FF4B2B;
        background-color: #FF4B2B;
        color: #FFFFFF;
        font-size: 12px;
        font-weight: bold;
        padding: 12px 45px;
        letter-spacing: 1px;
        text-transform: uppercase;
        transition: transform 80ms ease-in;
      }

      button:active {
        transform: scale(0.95);
      }

      button:focus {
        outline: none;
      }

      button.ghost {
        background-color: transparent;
        border-color: #FFFFFF;
      }

      form {
        background-color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100vw;
        text-align: center;
      }

      input, select {
        background-color: #eee;
        border: none;
        padding: 12px 15px;
        margin: 8px 0;
        width: 100%;
      }

      .container {
        background-color: #fff;
        display: flex;
        justify-content: space-between;
        width: 100%;
        max-width: 100%;
        min-height: 100%;
      }

      .logo{
        width: 25%;
        border-radius: 30px;
      }

      .form-container {
        top: 0;
        width: 25%;
        min-width: 300px;;
        height: 100%;
        overflow: hidden;
        transition: transform 0.6s ease-in-out;
        z-index: 100;
      }

      .overlay-container {
        top: 0;
        flex: 1;
        left: 50%;
        width: 50%;
        height: 100%;
        overflow: hidden;
        transition: transform 0.6s ease-in-out;
        z-index: 100;
        background: rgb(0,179,239);
        background: linear-gradient(0deg, rgba(0,179,239,1) 0%, rgba(45,219,253,1) 100%);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 0 0;
        
      }

      .container.right-panel-active{
        transform: translateX(100%);
        opacity: 1;
        z-index: 5;
        animation: show 0.6s;
      }

      @keyframes show {
        0%, 49.99% {
          opacity: 0;
          z-index: 1;
        }
        
        50%, 100% {
          opacity: 1;
          z-index: 5;
        }
      }

      .container.right-panel-active .overlay-container{
        transform: translateX(-100%);
      }

      .overlay {
        /* background: #FF416C; */
        /* background: -webkit-linear-gradient(to right, #FF4B2B, #FF416C); */
        /* background: linear-gradient(to right, #FF4B2B, #FF416C); */
        color: #FFFFFF;
        position: relative;
        height: 100%;
        width: 200%;
          transform: translateX(0);
        transition: transform 0.6s ease-in-out;
      }

      .container.right-panel-active {
          transform: translateX(50%);
      }

      .overlay-panel {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        top: 0;
        height: 100%;
        width: 50%;
        transform: translateX(0);
      }
      .login-image{
        width: 50%
      }

      .title{
        text-transform: uppercase;
      }
      p{
       font-size: 18px; 
      }
      @media only screen and (max-width: 876px) {

        body {
          width: 100%;
        }
        form{
          padding: 0 30px;
        }
        h1{
          font-size: 25px;
        }
        .container {
          flex-direction: column-reverse;
        }

          .overlay-container, .overlay-panel, .overlay, .form-container {
            width: 100%;
            flex: 1;
          }
         

          .logo{
            width: 30%;
            border-radius: 30px;
          }
      }

</style>
