<!-- hafalan.vue -->
<template>
  <div class="top"><h2>Hafalan</h2></div>
  <div class="hafalan">
  <div v-if="loading">Loading...</div>
  <div v-else-if="error">{{ error }}</div>
  <div v-else>
    <table class="table">
      <thead>
          <tr>
              <th>Tgl Setoran</th>
              <th>Hafalan</th>
              <th>Nilai</th>
          </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in hafalanData" :key="index">
              <td>{{ item.tgl_setoran }}</td>
              <td>{{ item.note_tahfidz }}</td>
              <td>{{ item.grade }}</td>
          </tr>
      </tbody>
  </table>
  </div>
</div>
</template>
<script>
import axios from 'axios';

export default {
name: 'HafalanPage',
data() {
  return {
    hafalanData: []
  };
},

async created() {
    try {
      const response = await axios.post('https://sekolah.tahfidzalquranplus.com/api/hafalan', {
        token: localStorage.getItem('token'),
      });

      // Cek respons dari server
      if (response.status) {
        // Simpan token atau data session jika diperlukan
        this.hafalanData = response.data.data;
        console.log(this.hafalanData)
      } 
    } catch (error) {
      // Tampilkan error jika ada masalah
      alert('Terjadi kesalahan: ' + error.message);
    }
  }

};
</script>

<style scoped>
  *,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  padding: 20px;
}

.top{
  position: fixed;
  top: 0;
  text-align: center;
  width: 100%;
  padding:20px;
  background-color: #fff;
}
.hafalan{
  padding-top: 70px;
  max-height: 85vh;
  overflow: scroll;
}

table {
  border-collapse: collapse;
  width: 100%;
  padding-bottom: 200px;

}
th,
td {
  padding: 8px;
  text-align: left;
  border: 1px solid #ddd;
}
tbody tr:hover {
  background-color: #f5f5f5;
}
th {
  background-color: #4caf50;
  color: white;
}
tfoot {
  background-color: #4a524a;
  color: white;
}

table tr:nth-child(even) {
  background-color: #f9f9f9; /* Baris genap */
}

table tr:nth-child(odd) {
  background-color: #e0e0e0; /* Baris ganjil */
}

</style>

