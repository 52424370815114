<!-- Murojaah.vue -->
<template>
    <div class="top"><h2>Murojaah</h2></div>
    <div class="murojaah">
    <div v-if="loading">Loading...</div>
    <div v-else-if="error">{{ error }}</div>
    <div v-else>
    <table class="table">
      <tbody>
        <tr>
          <th width="150px">Tanggal</th>
          <th width="150px">Pagi</th>
          <th width="130px"> Nilai</th>
          <th width="100px">Malam</th>
          <th width="150px"> Nilai</th>
        </tr>
      </tbody>
      <tbody>
        <tr v-for="(item, index) in murojaahData" :key="index">
          <td>{{ item.tgl_setoran }}</td>
          <td> {{ item.nilaiPagi }} </td>
          <td v-html="item.gradePagi"></td>
          <td> {{ item.nilaiMalam }} </td>
          <td v-html="item.gradeMalam"></td>

        </tr>
      </tbody>
    </table>
    </div>
  </div>
</template>
<script>
import axios from 'axios';

export default {
  name: 'MurojaahPage',
  data() {
    return {
      murojaahData: []
    };
  },
  
  async created() {
      try {
        const response = await axios.post('https://sekolah.tahfidzalquranplus.com/api/murojaah', {
          token: localStorage.getItem('token'),
        });

        // Cek respons dari server
        if (response.status) {
          // Simpan token atau data session jika diperlukan
          this.murojaahData = response.data.data;
          console.log(this.murojaahData)
        } 
      } catch (error) {
        // Tampilkan error jika ada masalah
        alert('Terjadi kesalahan: ' + error.message);
      }
    }
  
};
</script>

<style scoped>
   *,
    *::before,
    *::after {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
    }
    body {
      padding: 20px;
    }
    .top{
      position: fixed;
      top: 0;
      text-align: center;
      width: 100%;
      padding:20px;
      background-color: #fff;
    }
    .murojaah{
      padding-top: 70px;
      max-height: 85vh;
      overflow: scroll;
    }

    table {
      border-collapse: collapse;
      width: 200%;
      padding-bottom: 200px;

    }
    th,
    td {
      padding: 8px;
      text-align: left;
      border: 1px solid #ddd;
    }
    tbody tr:hover {
      background-color: #f5f5f5;
    }
    th {
      background-color: #4caf50;
      color: white;
    }
    tfoot {
      background-color: #4a524a;
      color: white;
    }

    table tr:nth-child(even) {
      background-color: #f9f9f9; /* Baris genap */
    }

    table tr:nth-child(odd) {
      background-color: #e0e0e0; /* Baris ganjil */
    }

</style>

