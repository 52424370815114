<template>
  <div class="home">
    <div>
      <router-view></router-view>
    </div>
    <nav class="bottom-nav">
      <router-link to="/home/murojaah" class="tab">Murojaah</router-link>
      <router-link to="/home/hafalan" class="tab">Hafalan</router-link>
      <a href="https://sekolah.tahfidzalquranplus.com/login" class="tab">Login to Web</a>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'HomePage'
};
</script>

<style scoped>
/* Styling sederhana untuk tab */

nav{
  display: flex;
  
  margin-left: -9px;
  text-decoration: none;
  width:100%;
  padding:10px 0 !important;
}

nav a{
  text-decoration: none !important;
  font-size: 14px;
}

.bottom-nav {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #f0f0f0;
}
.tab {
  text-align: center;
  flex: 1;
}

@media only screen and (max-width: 876px) {
  .bottom-nav {
    font-size: 10px;
  }
}
</style>
